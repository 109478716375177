@import url('https://fonts.cdnfonts.com/css/pp-neue-montreal');

body {
  margin: 0;
  font-family: 'PP Neue Montreal', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./assets/images/bg-wave.png), radial-gradient(100% 273.96% at 0% 0%, #FEF8F4 34.1%, #E5DEFD 75.19%, #CFC2FC 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 600px) {
  body {
    background-size: contain;
  }
}

#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace, 'PP Neue Montreal', sans-serif;
}